(function () {
    'use strict';

    angular
        .module('garanteasyApp')
        .factory('ProfileService', ProfileService);

    ProfileService.$inject = ['$q', '$http', '$log', 'API_URI', 'AuthServerProvider'];

    function ProfileService($q, $http, $log, API_URI, authServerProvider) {

        var dataPromise;

        const service = {
            getProfileInfo: getProfileInfo
        };

        return service;

        function getProfileInfo() {
            if (angular.isUndefined(dataPromise)) {
                dataPromise = $http.get(API_URI + 'api/profile-info', { responseType: "json" })
                    .then(function (result) {
                        if (result.data.activeProfiles) {
                            const response = {};
                            response.activeProfiles = result.data.activeProfiles;
                            response.ribbonEnv = result.data.ribbonEnv;
                            response.inProduction = result.data.activeProfiles.indexOf("prod") !== -1;
                            response.swaggerEnabled = result.data.activeProfiles.indexOf("swagger") !== -1;
                            return response;
                        } else {
                          authServerProvider.logout()
                        }
                    })
                    .catch(function (error) {
                      $log.error("Error calling profile-info: " + JSON.stringify(error))
                      authServerProvider.logout()
                    });
            }
            return dataPromise;
        }
    }
})();
